import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Menu from "../components/menu"
import MenuSection from "../components/menuSection"

const Tuxedos = () => (
  <Layout>
    <Seo title="Tuxedos" />
    <Banner>
      <StaticImage
        src="../images/tuxedos_banner.jpg"
        alt="Banner image showing a wedding photo, three men wearing tuxedos, and one man wearing a tuxedo"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
    <Menu title="TUXEDOS">
      <MenuSection title="Rentals &amp; Sales">
        <ul>
          <li>Tuxedos</li>
          <li>Suits</li>
          <li>Bow Ties</li>
          <li>Windsor Ties</li>
          <li>Pocket Squares</li>
          <li>Suspenders</li>
          <li>Shoes</li>
        </ul>
      </MenuSection>
      <MenuSection title="In-House Tailoring"></MenuSection>
    </Menu>
  </Layout>
)

export default Tuxedos
